import React, { useState, useEffect } from "react";
import "../key-concept/keyconcept.css";

// Page Components
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";

// Bootstrap Components
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import Accordion from "react-bootstrap/Accordion";
import { useParams, useHistory, Link } from "react-router-dom";

// Images
import pp_top from "../img/icon/Book_open_alt_light_b.png";
import img_7 from "../img/for.png";

// Other Components and Utilities
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import HTMLReactParser from "html-react-parser";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import PastPaperIcon from "../img/icon/pp-questionpaper.png";
import MarkSchemeIcon from "../img/icon/pp-markscheme.png";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

const Unit = () => {
  const history = useHistory();
  const { slug } = useParams();

  const [pastpaperData, setPastpaperData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };

  // State to manage active accordion keys for outer, middle, and inner Accordions
  const [activeOuterKey, setActiveOuterKey] = useState(null);
  const [activeMiddleKeys, setActiveMiddleKeys] = useState({});
  const [activeInnerKeys, setActiveInnerKeys] = useState({});
  const [innerAccordionData, setInnerAccordionData] = useState({});

  // State to store fetched data for each outer accordion item's inner data
  const [accordionData, setAccordionData] = useState({});

  // State to store the selected paper's data
  const [selectedPaperData, setSelectedPaperData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      console.log("Fetching pastpaperData with slug:", slug); // Log the slug
      try {
        const response = await fetch(
          `https://num8ers.cloud/api/appastpaperdate/${slug}`
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        console.log("Fetched pastpaperData:", data); // Log the fetched data
        setPastpaperData(data);
      } catch (error) {
        console.error("Error fetching pastpaperData:", error); // Log the error
        setError(error.message || "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [slug]);

  // Effect to fetch data when an outer accordion item is expanded
  useEffect(() => {
    if (activeOuterKey !== null && pastpaperData) {
      const index = parseInt(activeOuterKey, 10);
      console.log(
        `Active outer accordion key: ${activeOuterKey}, index: ${index}`
      ); // Log the active key and index
      const selectedPaper = pastpaperData[index];
      console.log(
        "Selected paper for fetching inner accordion data:",
        selectedPaper
      ); // Log the selected paper

      if (selectedPaper && !accordionData[selectedPaper.slug]) {
        fetchMiddleAccordionData(selectedPaper.slug);
      } else if (selectedPaper) {
        console.log(
          `Inner accordion data already fetched for slug: ${selectedPaper.slug}`
        ); // Log if data already exists
      }
    }
  }, [activeOuterKey, pastpaperData]);

  // Effect to fetch inner accordion data when middle accordion item is expanded
  useEffect(() => {
    if (activeOuterKey !== null && pastpaperData) {
      const outerIndex = parseInt(activeOuterKey, 10);
      const selectedPaper = pastpaperData[outerIndex];
      const middleKey = activeMiddleKeys[selectedPaper.slug];

      if (middleKey !== undefined && accordionData[selectedPaper.slug]?.data) {
        const middleData = accordionData[selectedPaper.slug].data;
        const middleIndex = parseInt(middleKey, 10);
        const selectedSession = middleData[middleIndex];

        if (selectedSession && !innerAccordionData[selectedSession.slug]) {
          fetchInnerAccordionData(selectedSession.slug);
        }
      }
    }
  }, [activeOuterKey, activeMiddleKeys, accordionData, pastpaperData]);

  // Function to fetch middle accordion data for a specific outer accordion item
  const fetchMiddleAccordionData = async (outerSlug) => {
    console.log(`Fetching inner accordion data for slug: ${outerSlug}`); // Log the slug being fetched

    // Set loading state for this middle accordion item
    setAccordionData((prev) => ({
      ...prev,
      [outerSlug]: { data: null, loading: true, error: null },
    }));

    try {
      const response = await fetch(
        `https://num8ers.cloud/api/pastpapersessions/${outerSlug}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(`Fetched inner data for slug ${outerSlug}:`, data); // Log the fetched data

      setAccordionData((prev) => ({
        ...prev,
        [outerSlug]: { data: data, loading: false, error: null },
      }));
    } catch (error) {
      console.error(
        `Error fetching inner accordion data for slug ${outerSlug}:`,
        error
      ); // Log the error
      setAccordionData((prev) => ({
        ...prev,
        [outerSlug]: {
          data: null,
          loading: false,
          error: error.message || "An error occurred",
        },
      }));
    }
  };

  // Function to fetch inner accordion data for a specific middle accordion item
  const fetchInnerAccordionData = async (middleSlug) => {
    console.log(`Fetching inner accordion data for slug: ${middleSlug}`);

    // Set loading state for this inner accordion item
    setInnerAccordionData((prev) => ({
      ...prev,
      [middleSlug]: { data: null, loading: true, error: null },
    }));

    try {
      const response = await fetch(
        `https://num8ers.cloud/api/appastpaper/${middleSlug}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log(`Fetched inner data for slug ${middleSlug}:`, data);

      setInnerAccordionData((prev) => ({
        ...prev,
        [middleSlug]: { data: data, loading: false, error: null },
      }));
    } catch (error) {
      console.error(
        `Error fetching inner accordion data for slug ${middleSlug}:`,
        error
      );
      setInnerAccordionData((prev) => ({
        ...prev,
        [middleSlug]: {
          data: null,
          loading: false,
          error: error.message || "An error occurred",
        },
      }));
    }
  };

  // Function to fetch the selected paper's data
  const fetchPaperData = async (paperSlug) => {
    console.log(`Fetching paper data for slug: ${paperSlug}`);
    try {
      const response = await fetch(
        `https://num8ers.cloud/api/appastpaperquestion/${paperSlug}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      console.log("Fetched paper data:", data);
      setSelectedPaperData(data);
    } catch (error) {
      console.error("Error fetching paper data:", error);
    }
  };

  if (loading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (error || !pastpaperData || pastpaperData.length === 0)
    return (
      <div>
        <Header />
        <Error message={error} />
      </div>
    );

  return (
    <section>
      <div className="">
        <Header />
        <section className="ap">
          <Container>
          <img
            src={BGRight1}
            alt="Splatter"
            className="splatter-image-right-1"
          />
          <img
            src={BGRight2}
            alt="Splatter"
            className="splatter-image-right-2"
          />
          <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle"
          />
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <Link to="/">Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/AP/${pastpaperData[0].curriculaslug}`}>
                        {pastpaperData[0].curriculaname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/AP/${pastpaperData[0].curriculaslug}`}>
                        {pastpaperData[0].subjname}
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span
                        onClick={() => goBackStepByStep(1)}
                        style={{ cursor: "pointer" }}
                      >
                        {pastpaperData[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading">
                  <h2>{pastpaperData[0].couresname} - Past Paper</h2>
                  <Row>
                    <Col sm={8}>
                      <p>
                        {HTMLReactParser(pastpaperData[0].couresdescription)}
                      </p>
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center">
                        <img src={pp_top} className="d" alt="Past Paper" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <section className="unit-page pt-60 pb-120">
        <Container>
          <Row className="past-paper-row">
            <Col sm={3} className="desktop-view">
              <div className="question-left">
                <Accordion
                  activeKey={activeOuterKey}
                  onSelect={(key) => {
                    console.log("Outer Accordion item selected with key:", key);
                    setActiveOuterKey(key);
                  }}
                >
                  {pastpaperData.map((paperdate, index) => {
                    console.log(
                      `Rendering outer accordion item: index=${index}, slug=${paperdate.slug}`
                    ); // Log each item
                    return (
                      <Accordion.Item
                        eventKey={index.toString()}
                        className="custom-accordion-item"
                        key={paperdate.slug}
                      >
                        <Accordion.Header>{paperdate.name}</Accordion.Header>
                        <Accordion.Body>
                          {/* Check the state for this outer accordion item's data */}
                          {accordionData[paperdate.slug]?.loading && <Loader />}
                          {accordionData[paperdate.slug]?.error && (
                            <Error
                              message={accordionData[paperdate.slug].error}
                            />
                          )}
                          {accordionData[paperdate.slug]?.data && (
                            <Accordion
                              activeKey={activeMiddleKeys[paperdate.slug]}
                              onSelect={(key) => {
                                console.log(
                                  `Middle Accordion item selected with key: ${key} in outer slug: ${paperdate.slug}`
                                );
                                setActiveMiddleKeys((prev) => ({
                                  ...prev,
                                  [paperdate.slug]: key,
                                }));

                                // Fetch inner accordion data when middle accordion item is expanded
                                const middleIndex = parseInt(key, 10);
                                const middleData =
                                  accordionData[paperdate.slug].data;
                                const selectedSession = middleData[middleIndex];

                                if (
                                  selectedSession &&
                                  !innerAccordionData[selectedSession.slug]
                                ) {
                                  fetchInnerAccordionData(selectedSession.slug);
                                }
                              }}
                            >
                              {accordionData[paperdate.slug].data.map(
                                (session, sessionIndex) => (
                                  <Accordion.Item
                                    eventKey={sessionIndex.toString()}
                                    key={session.slug || sessionIndex}
                                  >
                                    <Accordion.Header>
                                      {session.name ||
                                        `Session ${sessionIndex + 1}`}
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      {/* Check the state for this middle accordion item's data */}
                                      {innerAccordionData[session.slug]
                                        ?.loading && <Loader />}
                                      {innerAccordionData[session.slug]
                                        ?.error && (
                                        <Error
                                          message={
                                            innerAccordionData[session.slug]
                                              .error
                                          }
                                        />
                                      )}
                                      {innerAccordionData[session.slug]
                                        ?.data && (
                                        <Accordion
                                          activeKey={
                                            activeInnerKeys[session.slug]
                                          }
                                          onSelect={(key) => {
                                            console.log(
                                              `Inner Accordion item selected with key: ${key} in middle slug: ${session.slug}`
                                            );
                                            setActiveInnerKeys((prev) => ({
                                              ...prev,
                                              [session.slug]: key,
                                            }));

                                            const itemIndex = parseInt(key, 10);
                                            const itemData =
                                              innerAccordionData[session.slug]
                                                .data;
                                            const selectedItem =
                                              itemData[itemIndex];

                                            if (selectedItem) {
                                              fetchPaperData(selectedItem.slug);
                                            }
                                          }}
                                        >
                                          {innerAccordionData[
                                            session.slug
                                          ].data.map((item, itemIndex) => (
                                            <Accordion.Item
                                              eventKey={itemIndex.toString()}
                                              key={item.slug || itemIndex}
                                            >
                                              <Accordion.Header>
                                                {item.name ||
                                                  `Item ${itemIndex + 1}`}
                                              </Accordion.Header>
                                            </Accordion.Item>
                                          ))}
                                        </Accordion>
                                      )}
                                      {/* Default message when data is not yet loaded */}
                                      {!innerAccordionData[session.slug]
                                        ?.loading &&
                                        !innerAccordionData[session.slug]
                                          ?.data &&
                                        !innerAccordionData[session.slug]
                                          ?.error && (
                                          <div>Click to load details.</div>
                                        )}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                )
                              )}
                            </Accordion>
                          )}
                          {/* Optional: Show a default message if data is not yet loaded */}
                          {!accordionData[paperdate.slug]?.loading &&
                            !accordionData[paperdate.slug]?.data &&
                            !accordionData[paperdate.slug]?.error && (
                              <div>Click to load details.</div>
                            )}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>
              </div>
            </Col>
            {/* Main Content Area */}
            <Col sm={9}>
              <div>
                {selectedPaperData ? (
                  <div>
                    <div className="d-flex justify-content-end mb-3">
                      <button
                        className="past-paper-button"
                        onClick={() =>
                          window.open(selectedPaperData[0].question, "_blank")
                        }
                      >
                        <img
                          src={PastPaperIcon}
                          alt="Question Paper Icon"
                          className="button-icon"
                        />
                        Question Paper
                      </button>
                      <button
                        className="past-paper-button"
                        onClick={() =>
                          window.open(selectedPaperData[0].answare, "_blank")
                        }
                      >
                        <img
                          src={MarkSchemeIcon}
                          alt="Mark Scheme Icon"
                          className="button-icon"
                        />
                        Mark Scheme
                      </button>
                    </div>
                    <iframe
                      src={`${selectedPaperData[0].question}#page=2`}
                      width="100%"
                      height="800px"
                      title="Past Paper"
                    ></iframe>
                  </div>
                ) : (
                  <p>Select a past paper from the left to see details.</p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      {/* Modal to display additional information */}
      <Modal
        className="Formula-popup"
        size="lg"
        centered
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalContent && <p>{modalContent}</p>}
          {/* You can add more detailed content here */}
          <img src={img_7} alt="Formula" />
        </Modal.Body>
      </Modal>
      <Footer />
    </section>
  );
};

export default Unit;
