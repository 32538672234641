import React from "react";
import "./keyconcept.css";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Key from "../img/icon/Molecule_light.png";
import Question from "../img/icon/Quiz.png";
import Past from "../img/icon/Book_open_alt_light.png";
import Quiz from "../img/icon/Book.png";
import Key_w from "../img/icon/Molecule_light_w.png";
import Question_w from "../img/icon/Quiz_w.png";
import Past_w from "../img/icon/Book_open_alt_light_w.png";
import Quiz_w from "../img/icon/Book_w.png";
import Key_top from "../img/icon/Molecule_light_top.png";
import Key_top_w from "../img/icon/Molecule_light_w_1.png";
import Notebook_light from "../img/icon/notebook_light.png";
import { useGetAllunitQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import HTMLReactParser from "html-react-parser";
import { Link } from "react-scroll";
import KeyTopic from "./KeyTopic";
import { Pagenotfound } from "../404/Pagenotfound";
import { NavLink, useHistory } from "react-router-dom";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image

const Key_concept = (props) => {
  const history = useHistory();
  const goBackStepByStep = (step) => {
    for (let i = 0; i < step; i++) {
      history.goBack();
    }
  };
  const unitdata = useGetAllunitQuery(props.match.params.slug);
  if (unitdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );
  if (unitdata.data[0] == null)
    return (
      <div>
        <Header />

        <Error />
      </div>
    );
  if (unitdata.isError) return <h1>An error occured {unitdata.error.error}</h1>;
  return (
    <section className="questionbank-section">
      <div>
        <Header />
        <section className="ap">
          <Container>
            
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item>
                      <NavLink to="/">Home</NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={"/AP/" + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].curriculaname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <NavLink to={"/AP/" + unitdata.data[0].curriculaslug}>
                        {unitdata.data[0].subjname}
                      </NavLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <span onClick={() => goBackStepByStep(1)}>
                        {unitdata.data[0].couresname}
                      </span>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Key Concept</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="ap-heading mobile-view-top-header-style">
                  <h2>{unitdata.data[0].couresname}- Key Concept</h2>
                  <Row>
                    <Col sm={8}>
                      {HTMLReactParser(unitdata.data[0].couresdescription)}
                    </Col>
                    <Col sm={4}>
                      <div className="key-concept-top-right text-center mobile-view-right">
                        <img src={Key_top} className="d" />
                        <img src={Key_top_w} />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Dropdown>
                  <Dropdown.Toggle
                    className="unit-button"
                    id="dropdown-basic"
                    style={{ marginBottom: "50px" }} // Add margin-bottom inline style
                  >
                    <span>
                      <img src={Notebook_light} />
                      Jump to
                    </span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="unit-menu">
                    {unitdata.data.map((unit) => {
                      return (
                        <Dropdown.Item to={unit.unitnumber}>
                          <Link to={unit.unitnumber}>
                            Unit {unit.unitnumber}
                          </Link>
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
          </Container>
        </section>
      </div>

      {unitdata.data.map((unit) => {
        return (
          <Container className="mb-120" id={unit.unitnumber}>
            <h4 className="top-title">
              {unit.name}
            </h4>

            <KeyTopic propsdata={unit.slug} />
          </Container>
        );
      })}

      <Footer />
    </section>
  );
};

export default Key_concept;
