import React from 'react';
import './Success.css';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import { Container, Row, Col } from 'react-bootstrap';
import Img_1 from './../img/payment-succes.png';
import Img_2 from './../img/success-bg-black.png';
import Img_3 from './../img/error-payment-icon.png';

const Errorpayment = () => {
    return (
        <section>
            <div className="top-header-succes ap-mobile-view-header">
                <Header />
                <div className='success-page-payment pb-60 mobile-view-payment-right-img'>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                <div className='payment-left-side'>
                                    <div className='success-box-main'>
                                        <img src={Img_3} />
                                        <p className='payment-error-heading'>Payment unsuccessful</p>
                                        <h4>$ 300</h4>
                                        <hr className='mt-60 mb-60'></hr>
                                        <div className='list-payment-box'>
                                            <p><span className='lable'>Ref Number</span>
                                                <span className='main-content'>000085752257</span></p>
                                            <p><span className='lable'>Payment Time</span>
                                                <span className='main-content'>25-02-2024, 13:22:16</span></p>
                                            <p><span className='lable'>Payment Method</span>
                                                <span className='main-content'>Card</span></p>
                                            <p><span className='lable'>Sender Name</span>
                                                <span className='main-content'>JIt</span></p>
                                            <p><span className='lable'>Amount</span>
                                                <span className='main-content'>$ 300</span></p>
                                            <p><span className='lable'>Admin Fee</span>
                                                <span className='main-content'>$ 0.60</span></p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col sm={6}>
                                <div className='payment-right-side'>
                                    <img src={Img_1} className='d' />
                                    <img src={Img_2} className='w' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>

        </section>
    )
}

export default Errorpayment