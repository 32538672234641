import React from "react";
import "./courses.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import img_1 from "../img/courses.png";
import Key from "../img/icon/card_key.png";
import Question from "../img/icon/card_quiz.png";
import Past from "../img/icon/card_book.png";
import Quiz from "../img/icon/card_q.png";
import Key_w from "../img/icon/card_key_w.png";
import Question_w from "../img/icon/card_quiz_w.png";
import Past_w from "../img/icon/card_book_w.png";
import Quiz_w from "../img/icon/card_q_w.png";
import { Link } from "react-router-dom";
import { useGetAllletestcoursesQuery } from "../services/aplms";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import { NavLink } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";

const Courses = () => {
  const couresdata = useGetAllletestcoursesQuery();
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.isError) return <Error />;

  console.log(couresdata.data);

  return (
    <>
      <section className="latest-courses pt-120 pb-120 desktop-view"
      >
        <Container>
          <h4 className="latest-courses-title">Latest-Courses</h4>
          <Row>
            {couresdata.data.map((coures) => {
              console.log('Past Paper slug:', coures.slug);
              return (
                <Col sm={4}>
                  <div className="courses-box">
                    <div className="courses-box-top">
                      <div className="img_box_shadow">
                        <img src={coures.favicon_icon} />
                      </div>
                    </div>
                    <div className="courses-box-title">
                      <h4>{coures.name}</h4>
                      <div className="courses-box-content">
                        {/* First Row */}
                        <div className="courses-box-row">
                          <div className="courses-box-content-button">
                            <NavLink
                              to={"/Key-Concept/" + coures.slug}
                              className="courses-button-style"
                            >
                             <img src={Key} className="d" alt="Key Concepts" />
                              <img src={Key_w} alt="Key Concepts" /> Key
                              Concepts
                            </NavLink>
                          </div>
                          <div className="courses-box-content-button">
                            <NavLink
                              to={"/Question-Bank/" + coures.slug}
                              className="courses-button-style"
                            >
                              <img
                                src={Question}
                                className="d"
                                alt="Question Bank"
                              />
                              <img src={Question_w} alt="Question Bank" />{" "}
                              Question Bank
                            </NavLink>
                          </div>
                        </div> 

                        {/* Second Row */}
                        <div className="courses-box-row">
                          <div className="courses-box-content-button">
                            <NavLink
                              to={"/Past-Paper-Unit/" + coures.slug}
                              className="courses-button-style"
                            >
                              <img src={Past} className="d" alt="Past Papers" />
                              <img src={Past_w} alt="Past Papers" /> Past Papers
                            </NavLink>
                          </div>
                          <div className="courses-box-content-button">
                            <NavLink
                              to={"/Quiz/" + coures.slug}
                              className="courses-button-style"
                            >
                              <img src={Quiz} className="d" alt="Quizzes" />
                              <img src={Quiz_w} alt="Quizzes" /> Quizzes
                            </NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
      <section className="latest-courses pb-60 mobile-view">
        <Container>
          <h4 className="latest-courses-title">Latest-Courses</h4>
          <Row>
            {couresdata.data.map((coures) => {
              return (
                <Col sm={4}>
                  <div className="courses-box">
                    <div className="courses-box-top">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>{coures.name}</Accordion.Header>
                          <Accordion.Body>
                            <div className="img_box_shadow">
                              <img src={coures.favicon_icon} />
                            </div>
                            <div className="box-top-img-text">
                              <p>{coures.curriculaname}</p>
                              <p>{coures.subname}</p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="courses-box-title">
                      <div className="courses-box-content">
                        <div className="courses-box-content-button">
                          <NavLink
                            to={"/Key-Concept/" + coures.slug}
                            className="courses-button-style"
                          >
                            <img src={Key} className="d" /> <img src={Key_w} />{" "}
                            Key Concepts
                          </NavLink>
                        </div>
                        <div className="courses-box-content-button">
                          <NavLink
                            to={"/Question-Bank/" + coures.slug}
                            className="courses-button-style"
                          >
                            <img src={Question} className="d" />{" "}
                            <img src={Question_w} /> Question Bank
                          </NavLink>
                        </div>
                        <div className="courses-box-content-button">
                          <NavLink
                            to={"/Past-Paper-Unit/" + coures.slug}
                            className="courses-button-style"
                          >
                            <img src={Past} className="d" />
                            <img src={Past_w} /> Past Papers
                          </NavLink>
                        </div>
                        <div className="courses-box-content-button">
                          <NavLink
                            to={"/Quiz/" + coures.slug}
                            className="courses-button-style"
                          >
                            <img src={Quiz} className="d" />
                            <img src={Quiz_w} /> Quizzes
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Courses;
