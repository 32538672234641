import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { DarkLightModeProvider } from 'react-light-dark-mode';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import QuizHolder from './quiz/Context/QuizHolder';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <DarkLightModeProvider>
    <BrowserRouter>
      <Provider store={store}>
        <QuizHolder>
          <App />
        </QuizHolder>
      </Provider>
    </BrowserRouter>
  </DarkLightModeProvider>,
  document.getElementById('root')
);

reportWebVitals();
