import React, { useState } from "react";
import "./login.css"; // Ensure you have this CSS file
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import logo_d from "../img/logo/black-logo.png";
import logo_w from "../img/logo/white-logo.png";
import { useSendPasswordResetEmailMutation } from "../services/aplms";
import Footer from "./Footer";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";
import MailIcon from "../img/icon/MailIcon.png";

const Forgot = () => {
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });

  const [sendPasswordResetEmail] = useSendPasswordResetEmailMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Clear the previous error when starting a new submission
    setError({ status: false, msg: "", type: "" });

    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
    };

    if (actualData.email) {
      try {
        const res = await sendPasswordResetEmail(actualData);

        if (res.data && res.data.status === "success") {
          setError({
            status: true,
            msg: "Password Reset Email Sent. Check Your Email!",
            type: "success",
          });
        } else if (res.error) {
          setError({
            status: true,
            msg:
              res.error.data.message ||
              "Something went wrong. Please try again.",
            type: "error",
          });
        }
      } catch (error) {
        setError({
          status: true,
          msg: "Something went wrong. Please try again later.",
          type: "error",
        });
      }
    } else {
      setError({
        status: true,
        msg: "Please provide a valid email",
        type: "error",
      });
    }
  };

  return (
    <>
      <section className="login desktop-view">
        <Header />
        <div style={{ height: "100px" }}></div>
        <img src={BGRight1} alt="Splatter" className="splatter-image-right-1-login" />
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2-login" />
        <img src={BGMiddle} alt="Splatter" className="splatter-image-middle-login" />
        <Container>
          <Row className="login-from">
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_w} className="drack" alt="logo" />
                </Link>
                <Link to="/">
                  <img src={logo_d} className="lite" alt="logo" />
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div className="">
                <h4>Forgot Password</h4>

                {error.status && (
                  <div
                    className={`error-message ${
                      error.type === "success" ? "success" : "error"
                    }`}
                  >
                    {error.msg}
                  </div>
                )}

                <p className="from-bottom-text">
                  Enter your email to reset your password.
                </p>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4 email-input-group"
                    controlId="formBasicEmail"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={MailIcon}
                        alt="Mail Icon"
                        className="input-icon"
                      />
                      <input
                        className="custom-input email-input"
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </Form.Group>

                  <Button className="Signup-Button" type="submit">
                    Submit
                  </Button>

                  <p className="from-bottom-text">
                    By clicking on Submit, you agree to our{" "}
                    <span>Terms of Service</span> and{" "}
                    <span>Privacy Policy</span>.
                  </p>

                  <p className="forgot-password-text">
                    Remembered your password?{" "}
                    <Link to="/login" className="login-button-style">
                      Login
                    </Link>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>
    </>
  );
};

export default Forgot;
