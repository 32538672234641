import React from "react";
import { IoClose, IoSearch } from "react-icons/io5";
import { useState, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickOutside } from "react-click-outside-hook";
import MoonLoader from "react-spinners/MoonLoader";
import { useDebounce } from "../../hooks/debounceHook";
import axios from "axios";
import { TvShow } from "../tvShow";
import "./search.css";
import HTMLReactParser from "html-react-parser";

const containerVariants = {
  expanded: {
    height: "90vh",
  },
  collapsed: {},
};

const containerTransition = { type: "spring", damping: 22, stiffness: 150 };

export function SearchBar(props) {
  const [isExpanded, setExpanded] = useState(false);
  const [parentRef, isClickedOutside] = useClickOutside();
  const inputRef = useRef();
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [tvShows, setTvShows] = useState([]);
  const [noTvShows, setNoTvShows] = useState(false);

  const isEmpty = !tvShows || tvShows.length === 0;

  const changeHandler = (e) => {
    e.preventDefault();
    if (e.target.value.trim() === "") setNoTvShows(false);

    setSearchQuery(e.target.value);
  };

  const expandContainer = () => {
    setExpanded(true);
  };

  const collapseContainer = () => {
    setExpanded(false);
    setSearchQuery("");
    setLoading(false);
    setNoTvShows(false);
    setTvShows([]);
    if (inputRef.current) inputRef.current.value = "";
  };

  useEffect(() => {
    if (isClickedOutside) collapseContainer();
  }, [isClickedOutside]);

  const prepareSearchQuery = (query) => {
    const url = `https://num8ers.cloud/api/apsearchquestion/${query}`;

    return encodeURI(url);
  };

  const searchTvShow = async () => {
    if (!searchQuery || searchQuery.trim() === "") return;

    setLoading(true);
    setNoTvShows(false);

    const URL = prepareSearchQuery(searchQuery);

    const response = await axios.get(URL).catch((err) => {
      console.log("Error: ", err);
    });

    if (response) {
      if (response.data && response.data.length === 0) setNoTvShows(true);

      setTvShows(response.data);
    }

    setLoading(false);
  };

  useDebounce(searchQuery, 500, searchTvShow);

  return (

          <motion.div
            className="search-bar-container"
            animate={isExpanded ? "expanded" : "collapsed"}
            variants={containerVariants}
            transition={containerTransition}
            ref={parentRef}
          >
            <div className="search-input-container">
              <span className="search-icon">
                <IoSearch />
              </span>
              <input
                className="search-input"
                placeholder="Find books, solutions, and more..."
                onFocus={expandContainer}
                ref={inputRef}
                value={searchQuery}
                onChange={changeHandler}
              />
              <AnimatePresence>
                {isExpanded && (
                  <motion.span
                    className="close-icon"
                    key="close-icon"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={collapseContainer}
                    transition={{ duration: 0.2 }}
                  >
                    <IoClose />
                  </motion.span>
                )}
              </AnimatePresence>
            </div>
            {isExpanded && <span className="line-separator" />}
            {isExpanded && (
              <div className="search-content">
                {isLoading && (
                  <div className="loading-wrapper">
                    <MoonLoader loading color="#000" size={20} />
                  </div>
                )}
                {!isLoading && isEmpty && !noTvShows && (
                  <div className="loading-wrapper">
                    <span className="warning-message">Start typing to Search</span>
                  </div>
                )}
                {!isLoading && noTvShows && (
                  <div className="loading-wrapper">
                    <span className="warning-message">Question Not found!</span>
                  </div>
                )}
                {!isLoading && !isEmpty && (
                  <>
                    {tvShows.map((question) => (
                      <TvShow
                        className="search-bar-text"
                        key={question.id}
                        name={question.name}
                      />
                    ))}
                  </>
                )}
              </div>
            )}
          </motion.div>

  );
}
