const storeToken = (value) => {
  localStorage.setItem('token', value);
};
const getToken = () => {
  let token = localStorage.getItem('token');
  return token;
};
const removeToken = (value) => {
  localStorage.removeItem(value);
};
const storeUserinfo = (value) => {
  localStorage.setItem('userinfo', value);
};
const getUserinfo = () => {
  let userinfo = localStorage.getItem('userinfo');
  return userinfo;
};
const removeUserinfo = (value) => {
  localStorage.removeItem(value);
};

export {
  storeToken,
  getToken,
  removeToken,
  storeUserinfo,
  getUserinfo,
  removeUserinfo,
};
