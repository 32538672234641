import React from 'react';
import { NavLink } from 'react-router-dom';
import './CurriculumSquares.css'; // Importing the CSS for hover effect

const CurriculumSquares = ({ curriculumData }) => {
  const squareCount = 5; // Number of squares
  const size = 200; // Size of each square
  const colors = ['#0498FF', '#9772FF', '#D960E1', '#FA5B56', '#FEA807']; // Array of colors for the squares
  const borderRadius = 20; // Adjust this value for more or less rounding
  const borderWidth = 1; // Width of the white border

  return (
    <svg width="400" height="400" viewBox="0 0 400 400" className="curriculum-squares">
      {curriculumData.slice(0, squareCount).map((curriculumdata, index) => {
        const rotation = index * 15; // Rotate each square by 15 degrees more than the last
        const xPosition = (400 - size) / 2; // Centering the square on the x-axis
        const yPosition = (400 - size) / 2; // Centering the square on the y-axis

        return (
          <NavLink
            to={"/AP/" + curriculumdata.slug}
            key={index}
            className={`square-link square-group-${index}`}
          >
            <g>
              <rect
                className={`square-${index}`}
                x={xPosition}
                y={yPosition}
                width={size}
                height={size}
                rx={borderRadius}
                ry={borderRadius}
                fill={colors[index % colors.length]}
                opacity="0.5"
                stroke="white"
                strokeWidth={borderWidth}
                transform={`rotate(${rotation}, 200, 200)`}
              />
              <text
                x="200" // Centering the text horizontally
                y="210" // Centering the text vertically (adjust as needed)
                textAnchor="middle"
                fill="white"
                fontSize="24"
                fontFamily="Nunito"
                className={`square-text square-text-${index}`}
              >
                {curriculumdata.name}
              </text>
            </g>
          </NavLink>
        );
      })}
      {/* Default Title Text */}
      <text
        x="200" // Centering the text horizontally
        y="210" // Centering the text vertically (adjust as needed)
        textAnchor="middle"
        fill="white"
        fontSize="24"
        fontFamily="Nunito"
        className="default-title"
      >
        Explore Curricula
      </text>
    </svg>
  );
};

export default CurriculumSquares;
