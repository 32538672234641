import React from 'react';
import img_1 from '../img/icon/Arrow_right_light.png';
import img_2 from '../img/question-img-1.png';
import img_3 from '../img/icon/Video_light.png';
import img_4 from '../img/book-img.png';
import img_5 from '../img/icon/Arrow_right_light_w.png';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Accordion from 'react-bootstrap/Accordion';
import { useGetAllkeyconceptpaperQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import Intro from './Intro';
import Error from '../errordata/Error';

const Practicepaper = (props) => {
  const slug = props.propspaperData;
  const practicpapers = useGetAllkeyconceptpaperQuery(slug);
  if (practicpapers.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (practicpapers.data[0] == null) return <Error />;
  if (practicpapers.isError)
    return <h1>An error occured {practicpapers.error.error}</h1>;
  return (
    <>
      <Accordion.Body>
        <div className="desktop-view">
          {practicpapers.data.map((paper) => {
            return (
              <Link
                to={'/Key-Concept-Question/' + paper.slug}
                className="unit-question-link">
                {paper.name}
                <img
                  src={img_1}
                  className="d"
                />
                <img src={img_5} />
              </Link>
            );
          })}
        </div>
        <div className="mobile-view accordion_mobile_view_style">
          <div className="accordion_mobile_view_style_1">
            {practicpapers.data.map((paper) => {
              return (
                <Link
                  to={'/Key-Concept-Question/' + paper.slug}
                  className="unit-question-link">
                  {paper.name}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="mobile-view intro_mobile_view_style">
          <Intro propsintroData={slug} />
        </div>
      </Accordion.Body>
    </>
  );
};

export default Practicepaper;
