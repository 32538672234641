import React from 'react';
import './speaks.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import img_1 from '../img/speaks.png';

const Speaks = () => {
  return (
    <>
      <section className="speaks-top-box pt-120 pb-120 desktop-view">
        <Container>
          <Row>
            <Col sm={12}>
              <h2 className="title">Where experience speaks.</h2>
            </Col>
          </Row>
          <Row className="align-items pt-60">
            <Col sm={4}>
              <div className="speaks-left-img">
                <img src={img_1} />
              </div>
            </Col>
            <Col sm={8}>
              <div className="speaks-right-content">
                <h4>Miss Sugata</h4>
                <p>
                  I have had the pleasure of working with <br></br>
                  Num8ers company for the past several years, <br></br>
                  and I can confidently say that their services have been a
                  <br></br>
                  game-changer for my business. <br></br>
                  Num8ers has consistently provided top-notch financial and
                  accounting <br></br>
                  solutions that have not only streamlined <br></br>
                  our financial processes but have also helped us make informed
                  decisions.
                </p>
                <br></br>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="speaks-top-box pt-60 pb-60 mobile-view">
        <Container>
          <Row className="align-items">
            <Col sm={4}>
              <div className="speaks-left-img">
                <img src={img_1} />
              </div>
            </Col>
            <Col sm={8}>
              <div className="speaks-right-content">
                <h4>Miss Sugata</h4>
                <p>
                  I have had the pleasure of working with <br></br>
                  Num8ers company for the past several years, <br></br>
                  and I can confidently say that their services have been a
                  <br></br>
                  game-changer for my business. <br></br>
                  Num8ers has consistently provided top-notch financial and
                  accounting <br></br>
                  solutions that have not only streamlined <br></br>
                  our financial processes but have also helped us make informed
                  decisions.
                </p>
                <br></br>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Speaks;
