import React from 'react';
import './ap.css';
// bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useGetFindcurriculumQuery } from '../../services/aplms';
import Loader from '../../loader/Loader';
import Error from '../../errordata/Error';
import HTMLReactParser from 'html-react-parser';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

const Top_header = (props) => {
  const slug = props.propsintroData;
  const curriculum = useGetFindcurriculumQuery(slug);
  if (curriculum.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (curriculum.data[0] == null)
    return (
      <div>
        <Error />
      </div>
    );

  if (curriculum.isError) return <Error />;

  return (
    <section className="ap">
      <Container>
        {curriculum.data.map((curr) => {
          return (
            <Row className="pb-120">
              <Col sm={12}>
                <div className="top-nav">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="/All-Curriculum">
                      Curriculum
                    </Breadcrumb.Item>

                    <Breadcrumb.Item href="/All-Curriculum">
                      {curr.name}
                    </Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </Col>
              <Col sm={12}>
                <div className="coursecard-heading">
                  <h2>{curr.name}</h2>
                  <p>{HTMLReactParser(curr.description)}</p>
                </div>
              </Col>
            </Row>
          );
        })}
      </Container>
    </section>
  );
};

export default Top_header;
