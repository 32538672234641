import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './pagenotfound.css';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import img11 from '../img/blacknodata.png';
import img111 from '../img/whitenodata.png';
import { Link, useHistory } from 'react-router-dom';

export const Pagenotfound = () => {
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };
  return (
    <>
      <div className="pagenotfoundheader">
        <Header />
      </div>
      <section className="Pagenotfound Pagenotfound_hingh_full">
        <div className="background-img-notfound desktop-view">
          <img
            src={img11}
            className="w"
          />
          <img
            src={img111}
            className="d"
          />
        </div>
        <div className="background-img-notfound mobile-view">
          <img
            src={img11}
            className="w"
          />
          <img
            src={img111}
            className="d"
          />
        </div>
        <Container>
          <Row>
            <Col sm={4}>
              <div className="page-not-found-content">
                <h2>Ooops...</h2>
                <h4>Page not found</h4>
                <p>
                  The page you are looking for doesn’t exist or an other error
                  occurred, go back to home page.
                </p>
                <Link
                  to="/"
                  className="page-not-found-to-go-back"
                  onClick={goBack}>
                  Go Back
                </Link>
              </div>
            </Col>
            <Col sm={8}></Col>
          </Row>
        </Container>
      </section>
      <div>
        <Footer />
      </div>
    </>
  );
};
