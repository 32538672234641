import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  name: '',
  phone: '',
  school: '',
  country: '',
  plan_name: '',
  plane_status: '',
  plan_end: '',
  plan_id: '',
  amount: '',
  plan_intervel: '',
  free_trial: '',
  invoice_paf: '',
};

export const userSlice = createSlice({
  name: 'user_info',
  initialState,
  reducers: {
    setUserInfo: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.school = action.payload.school;
      state.country = action.payload.country;
      state.plan_name = action.payload.plan_name;
      state.plane_status = action.payload.plane_status;
      state.plan_id = action.payload.plan_id;
      state.plan_end = action.payload.plan_end;
      state.amount = action.payload.amount;
      state.plan_intervel = action.payload.plan_intervel;
      state.free_trial = action.payload.free_trial;
      state.invoice_paf = action.payload.invoice_paf;
    },
    unsetUserInfo: (state, action) => {
      state.email = action.payload.email;
      state.name = action.payload.name;
      state.school = action.payload.school;
      state.country = action.payload.country;
      state.plan_name = action.payload.plan_name;
      state.plane_status = action.payload.plane_status;
      state.plan_id = action.payload.plan_id;
      state.plan_end = action.payload.plan_end;
      state.amount = action.payload.amount;
      state.plan_intervel = action.payload.plan_intervel;
      state.free_trial = action.payload.free_trial;
      state.invoice_paf = action.payload.invoice_paf;
    },
  },
});

export const { setUserInfo, unsetUserInfo } = userSlice.actions;

export default userSlice.reducer;
