import { React, useState } from "react";
import "./login.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useHistory } from "react-router-dom";
import Forgot from "./Forgot";
import Header from "../layout/header/Header";
import logo_d from "../img/logo/black-logo.png";
import logo_m_d from "../img/icon/mobile-view-logo.png";
import logo_w from "../img/logo/white-logo.png";
import img_1 from "../img/icon/Social g.png";
import img_2 from "../img/icon/Social l.png";
import img_3 from "../img/icon/Social f.png";
import Footer from "./Footer";
import { useRegisterUserMutation } from "../services/aplms";
import { storeToken } from "../services/LocalStorageService";
import BGRight1 from "../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image
import MailIcon from "../img/icon/MailIcon.png";
import PassIcon from "../img/icon/PasswordIcon.png";
import GoogleIcon from "../img/icon/GoogleIcon.png";
import SignUpImage from "../img/icon/SignUp Image.png";
import LockIcon from "../img/icon/LockIcon.png";
const Sinup = () => {
  window.scrollTo(0, 0);
  const [error, setError] = useState({
    status: false,
    msg: "",
    type: "",
  });
  const history = useHistory();
  const [registerUser] = useRegisterUserMutation();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const actualData = {
      email: data.get("email"),
      fname: data.get("fname"),
      lname: data.get("lname"),
      country: data.get("country"),
      phone: data.get("phone"),
      password: data.get("password"),
      cpassword: data.get("password"),
    };
    if (actualData.email && actualData.password && actualData.cpassword) {
      if (actualData.password === actualData.cpassword) {
        const res = await registerUser(actualData);
        console.log(res);
        if (res.data.status === "success") {
          // Store Token Code here
          storeToken(res.data.token);
          history.push("/");
        }
        if (res.data.status === "failed") {
          setError({ status: true, msg: res.data.message, type: "error" });
        }
      } else {
        setError({
          status: true,
          msg: "Password and Confirm Password Doesn't Match",
          type: "error",
        });
      }
    } else {
      setError({ status: true, msg: "All Fields are Required", type: "error" });
    }
  };
  return (
    <>
      <section className="login desktop-view">
        <Header />
        <img src={BGRight1} alt="Splatter" className="splatter-image-right-1-login" />
        <img src={BGRight2} alt="Splatter" className="splatter-image-right-2-login" />
        <img src={BGMiddle} alt="Splatter" className="splatter-image-middle-login" />
        <div style={{ height: "100px" }}></div>
        <Container>
          <Row className="login-from">
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={SignUpImage} className="drack" />
                </Link>
                <Link to="/">
                  <img src={SignUpImage} className="lite" />
                </Link>
              </div>
            </Col>
            <Col sm={6}>
              <div className="">
                <h4>Sign Up</h4>
                {error.status && (
                  <div
                    className={`error-message ${error.status ? "show" : ""}`}
                  >
                    {error.msg}
                  </div>
                )}
                {error.status && (
                  <div className="error-message">{error.msg}</div>
                )}

                <p className="from-bottom-text">
                  Access to 80+ hours of courses, tutorials, and source files.
                </p>

                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group
                    className="mb-4 email-input-group"
                    controlId="formBasicEmail"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={MailIcon}
                        alt="Mail Icon"
                        className="input-icon"
                      />
                      <input
                        className="custom-input email-input" // Applying the custom class
                        placeholder="Email Address"
                        name="email"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-2 password-input-group"
                    controlId="formBasicPassword"
                  >
                    <div className="input-icon-wrapper">
                      <img
                        src={LockIcon}
                        alt="Password Icon"
                        className="input-icon"
                      />
                      <input
                        type="password"
                        className="custom-input password-input"
                        placeholder="Password"
                        name="password"
                        minLength="8"
                        required
                      />
                    </div>
                  </Form.Group>

                  <Button className="Signup-Button" type="submit">
                    Sign Up
                  </Button>
                  <p className="from-bottom-text">
                    By clicking on Sign up, you agree to our{" "}
                    <span>Terms of service</span> and{" "}
                    <span>Privacy policy</span>.
                  </p>

                  <p className="forgot-password-text">
                    Already have an account?{" "}
                    <Link to="/login" className="login-button-style">
                      Sign In
                    </Link>
                  </p>

                  <p className="forgot-password-text">
                    Forgot Password?{" "}
                    <Link to="/forgot" className="login-button-style">
                      Forgot Password
                    </Link>
                  </p>

                  <h5 className="continue-with-text">Or continue with...</h5>

                  <div className="continue-with-google">
                    <img
                      src={GoogleIcon}
                      alt="Google Icon"
                      className="google-icon"
                    />
                    <h4 className="google-text">Google</h4>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Container>
      </section>

      {/* mobile view */}
      <section className="login mobile-view">
        <Header />
        <Container>
          <Row>
            <Col sm={6} className="width-from">
              <div className="login-top-logo">
                <Link to="/">
                  <img src={logo_m_d} className="drack" />
                </Link>
                <Link to="/">
                  <img src={logo_m_d} className="lite-logo" />
                </Link>
              </div>
              <h4 className="signup-page-mobile-view-top-header mt-30 mb-30">
                Discover. Dive. Dominate.
              </h4>
            </Col>
            <Col sm={6}>
              <div className="login-from">
                {error.status ? (
                  <p className="p_alrat" severity={error.type}>
                    {error.msg}
                  </p>
                ) : (
                  ""
                )}
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <Form.Group className="mb-4" controlId="formBasicEmail">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      id="email"
                      name="email"
                      placeholder="Email"
                    />
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      id="password"
                      name="password"
                      placeholder="Password"
                    />
                    <div className="password">
                      <Link to="/forgot" className="login-button-style">
                        Forgot Password?
                      </Link>
                    </div>
                  </Form.Group>

                  <Form.Group className="mb-4" controlId="formBasicPassword">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      name="cpassword"
                      type="password"
                      id="cpassword"
                      placeholder="Confirm  Password"
                    />
                  </Form.Group>

                  <Form.Group className="mb-5" controlId="formBasicCheckbox">
                    <Form.Check
                      type="checkbox"
                      class="form-check-input"
                      label="Remember me"
                    />
                  </Form.Group>
                  <Row>
                    <Col sm={12}>
                      <p className="or-login-text">or Login with</p>
                    </Col>
                    <div className="or-login-button">
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_1} /> Google
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button variant="primary" type="submit">
                          <img src={img_2} /> LinkedIn
                        </Button>
                      </div>
                      <div className="login-button">
                        <Button>
                          <img src={img_3} /> Facebook
                        </Button>
                      </div>
                    </div>
                  </Row>
                  <Button variant="primary" type="submit">
                    Start Learning
                  </Button>
                  <p className="from-bottom-text">
                    By continuing, you accept our <span>Terms of Use</span>, our
                    <span>Privacy Policy</span> and that your data is stored in
                    the cloud.
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Sinup;
