import React, { useState, useEffect, useContext } from "react";
import "./header.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import img_1 from "../../img/icon/Molecule_light.png";
import img_1_d from "../../img/icon/Molecule_light_w.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useGetAllcoursesQuery } from "../../services/aplms";
import Right_Arror_d from "../../img/icon/rigth_arror_drack.png";
import Loader from "../../loader/Loader";
import Error from "../../errordata/Error";
import Question from "../../img/icon/Quiz.png";
import Question_w from "../../img/icon/Quiz_w.png";
import Past from "../../img/icon/Book_open_alt_light.png";
import Past_w from "../../img/icon/Book_open_alt_light_w.png";
import Quiz from "../../img/icon/Book.png";
import Quiz_w from "../../img/icon/Book_w.png";
import { NavLink } from "react-router-dom";

const Ritsideheader = (props, key) => {
  const slug = props.propsData;

  const couresdata = useGetAllcoursesQuery(slug);
  if (couresdata.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (couresdata.data[0] == null)
    return (
      <div>
        <h1></h1>
      </div>
    );
  if (couresdata.isError) return <Error />;

  return (
    <>
      <Col sm={10}>
        <Tab.Content>
          <Tab.Pane eventKey={slug} active={true}>
            <Breadcrumb className="custom-breadcrumb">
              <Breadcrumb.Item href="#">
                {couresdata.data[0].curriculaname}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {couresdata.data[0].subname}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Row className="row-background">
              {couresdata.data.map((coures) => (
                <Col sm={3} key={coures.slug}>
                  <h6 className="top_heading">{coures.name}</h6>
                  <hr className="divider" />
                  <ul className="list-unstyled megamenu-heading-top">
                    <li className="nav-item">
                      <NavLink
                        to={`/Key-Concept/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Key Concepts
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Question-Bank/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Question Bank
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Past-Paper-Unit/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Past Papers
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to={`/Quiz/${coures.slug}`}
                        className="nav-link text-small text-white pb-0"
                      >
                        Quizzes
                      </NavLink>
                    </li>
                  </ul>
                </Col>
              ))}
            </Row>
          </Tab.Pane>
        </Tab.Content>
      </Col>
    </>
  );
};

export default Ritsideheader;
