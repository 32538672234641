import React from 'react';
import './filepop.css';
import HTMLReactParser from 'html-react-parser';

const Filepop = (props) => {
  return (
    <>
        <div className="popup-box pfd_popup_main animate__animated animate__backInDown">
          <div className="main-pfd-popup">
            <span
              className="close-icon btn-close pfd_colse_icon"
              onClick={props.hide}>
              x
            </span>
            <div className="model-body">
              <object
                data={props.file} className='object_pdf'></object>
            </div>
          </div>
        </div>
    </>
  );
};

export default Filepop;
