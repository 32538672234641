// import React from "react";
// import styled from "styled-components";
// import HTMLReactParser from "html-react-parser";
// import { NavLink } from "react-router-dom";

// const TvShowContainer = styled.div`
//   width: 100%;
//   min-height: 6em;
//   display: flex;
//   border-bottom: 2px solid #d8d8d852;
//   padding: 6px 8px;
//   align-items: center;
// `;

// const Thumbnail = styled.div`
//   width: auto;
//   height: 100%;
//   display: flex;
//   flex: 0.4;

//   img {
//     width: auto;
//     height: 100%;
//   }
// `;

// const Name = styled.h3`
//   font-size: 15px;
//   color: #000;
//   margin-left: 10px;
//   flex: 2;
//   display: flex;
// `;

// const Rating = styled.span`
//   color: #a1a1a1;
//   font-size: 16px;
//   display: flex;
//   flex: 0.2;
// `;

// export function TvShow(props) {
//   const { thumbanilSrc, name, subname, coursname, slug } = props;


//   return (
//     <NavLink to={"/searchquestion/" + slug}>
//       <TvShowContainer>
//         {/* <Thumbnail>
//           <img src={HTMLReactParser(thumbanilSrc)} />
//           <p className="search-bar">{HTMLReactParser(thumbanilSrc)}</p>
//         </Thumbnail> */}
//         <Name>{name}</Name>
//         <Rating>{subname}<br></br>{coursname}</Rating>
//       </TvShowContainer>
//     </NavLink>
//   );
// }


import React from "react";
import styled from "styled-components";
import HTMLReactParser from "html-react-parser";
import { NavLink } from "react-router-dom";

const TvShowContainer = styled.div`
  width: 100%;
  min-height: 6em;
  display: flex;
  border-bottom: 2px solid #d8d8d852;
  padding: 6px 8px;
  align-items: center;
`;

const Thumbnail = styled.div`
  width: auto;
  height: 100%;
  display: flex;
  flex: 0.4;

  img {
    width: auto;
    height: 100%;
  }
`;

const Name = styled.h3`
  font-size: 15px;
  color: #000;
  margin-left: 10px;
  flex: 2;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Rating = styled.span`
  color: #a1a1a1;
  font-size: 16px;
  display: flex;
  flex: 0.2;
`;

export function TvShow(props) {
  const { thumbnailSrc, name, subname, coursname, slug } = props;

  return (
    <NavLink to={"/searchquestion/" + slug}>
      <TvShowContainer>
        {/* <Thumbnail>
          <img src={HTMLReactParser(thumbnailSrc)} />
          <p className="search-bar">{HTMLReactParser(thumbnailSrc)}</p>
        </Thumbnail> */}
        <Name>{name}</Name>


        <Rating>
          {subname}
          {subname !== coursname && ` - ${coursname}`}
        </Rating>
      </TvShowContainer>
    </NavLink>
  );
}
