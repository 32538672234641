import React, { useState } from 'react';
import { createContext } from 'react';

const QuizContext = createContext();

export default function QuizHolder(props) {
  const [quizzes, setQuizzes] = useState([]);





  return (
    <QuizContext.Provider
      value={{
        quizzes,
        setQuizzes,


      }}>
      {props.children}
    </QuizContext.Provider>
  );
}

export { QuizContext };
