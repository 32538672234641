import React from 'react';
import Header from './layout/header/Header';
import Footer from './layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import Key_top from './img/icon/hide_eye_light.png';
import Key_top_w from './img/icon/hide_eye_light_w.png';

const Privacypolicy = () => {
  return (
    <section className="Privacy-Policy">
      <Header />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="ap-heading">
              <Row className="flex-end">
                <Col sm={8}>
                  <h2>Privacy Policy</h2>
                </Col>
                <Col sm={4}>
                  <div className="key-concept-top-right text-center">
                    <img
                      src={Key_top}
                      className="d"
                    />
                    <img src={Key_top_w} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="Privacy-Policy-content">
                    <p>Privacy Policy</p>
                    <p>Last updated: June 15, 2022</p>
                    <p>
                      This Privacy Policy describes Our policies and procedures
                      on the collection, use and disclosure of Your information
                      when You use the Service and tells You about Your privacy
                      rights and how the law protects You.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </section>
  );
};

export default Privacypolicy;
