import React, { useContext, useEffect, useState } from "react";
import "./quiz.css";
import { Link } from "react-router-dom";
import Header from "../layout/header/Header";
import Footer from "../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Loader from "../loader/Loader";
import Error from "../errordata/Error";
import { useGetAllquizquctionQuery } from "../services/aplms";
import HTMLReactParser from "html-react-parser";
import happy_img from "../img/happy_light.png";
import sad_img from "../img/Sad_light.png"; // Corrected variable name
import Quizpopup from "./Quizpopup";
import { QuizContext } from "./Context/QuizHolder";
import BGRight1 from "../img/Bg Right 1.png";
import BGRight2 from "../img/Bg Right 2.png";
import BGMiddle from "../img/BG Middle.png";

const Box = ({ current, next, props }) => {
  const questiondata = useGetAllquizquctionQuery(props.match.params.slug);

  const [model, setModel] = useState(false);
  const [check, setCheck] = useState(false);
  const [tempdata, setTempdata] = useState();
  const [showh, sethShow] = useState(false);
  const handleClose = () => sethShow(false);
  const handleShow = () => sethShow(true);
  const [correct, setCorrect] = useState(null);
  const [result, setResult] = useState(0);
  const [classadd, setClassadd] = useState("");
  const [disabledcheck, setDisabledcheck] = useState(false);
  const [ans, setAns] = useState("");
  const [exit, setExit] = useState(null);
  const { quizzes, setQuizzes } = useContext(QuizContext);

  const getData = (photo) => {
    let tempData = [photo];
    setTempdata((catdata) => [1, ...tempData]);
    return setModel(true);
  };

  console.log(questiondata);

  // Add keyboard event listeners
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.target.tagName === "INPUT" ||
        event.target.tagName === "TEXTAREA"
      ) {
        // Don't handle shortcuts when typing in input fields
        return;
      }

      switch (event.key.toLowerCase()) {
        case "enter":
          if (disabledcheck) {
            // If 'Continue' button is active
            nextHandler();
          } else if (check) {
            // If 'Check' button is active
            saveHandler();
          }
          break;
        case "a":
        case "b":
        case "c":
        case "d":
          handleSelectAnswer(event.key.toUpperCase());
          break;
        case "r":
          handleResetExercise();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [check, disabledcheck, ans]);

  // Define handler functions
  const handleSelectAnswer = (key) => {
    setAns(key);
    setCheck(true);
  };

  const handleResetExercise = () => {
    // Implement the logic to reset the exercise
    console.log("Exercise reset");
    // Reset state variables
    setAns("");
    setCheck(false);
    setDisabledcheck(false);
    setCorrect(null);
  };

  useEffect(() => {
    if (questiondata.data) {
      setQuizzes(questiondata.data);
      setCorrect(null);
      setExit(
        <>
          <Link to="/Result" className="quiz-box">
            Show Result
          </Link>
        </>
      );
    }
  }, [questiondata.data, setQuizzes]);

  // Handler for saving the answer and updating UI
  const saveHandler = () => {
    const quizData = quizzes;

    if (!quizData || !quizData.questions) {
      return;
    }

    const correctAnswer = quizData.questions[current].answer;
    const answerIndicator = correctAnswer === ans ? 1 : 0;

    // Retrieve stored answer indicators (1 for correct, 0 for incorrect)
    const storedIndicators =
      JSON.parse(localStorage.getItem("answerIndicators")) || [];

    // Update the current question's indicator in localStorage
    storedIndicators[current] = answerIndicator;
    localStorage.setItem("answerIndicators", JSON.stringify(storedIndicators));

    // Check if the selected answer is correct
    if (correctAnswer === ans) {
      setClassadd("A");

      // Only add marks for the correct answer to the result
      setResult((prevResult) => {
        const updatedResult = prevResult + 1; // Hardcode to 1 NP per correct answer
        setQuizzes((prevQuizzes) => ({
          ...prevQuizzes,
          result: updatedResult,
        }));
        return updatedResult;
      });

      setCorrect(
        <>
          <Button
            onClick={() => {
              nextHandler();
            }}
            className="quiz-box green-button"
          >
            Continue
          </Button>
          <div className="wrong-answer">
            <p>Your Answer is Correct</p>
          </div>
          <div className="see-why-popup">
            <p
              className="heand-icon"
              onClick={() => getData(quizData.questions[current].fullanswer)}
            >
              See Why
            </p>
            <img src={happy_img} alt="Happy" />
          </div>
        </>
      );
    } else {
      // Incorrect answer case - no result update
      setClassadd("B");

      setCorrect(
        <>
          <Button
            onClick={() => {
              nextHandler();
            }}
            className="quiz-box red-button"
          >
            Continue
          </Button>
          <div className="wrong-answer red">
            <p>Your Answer is Wrong</p>
          </div>
          <div className="see-why-popup red">
            <p
              className="heand-icon"
              onClick={() => getData(quizData.questions[current].fullanswer)}
            >
              See Why
            </p>
            <img src={sad_img} alt="Sad" />
          </div>
        </>
      );
    }

    // Handle end of quiz case
    if (current + 1 === quizData.questions.length) {
      setCheck(false);
      setCorrect(null);
      setDisabledcheck(false);

      setExit(
        <>
          <Link to="/Result" className="quiz-box">
            Show Result
          </Link>
        </>
      );
    } else {
      setDisabledcheck(true);
    }

    // Reset 'check' state
    setCheck(false);
  };

  // Modified nextHandler function
  const nextHandler = () => {
    next(current + 1);
    setDisabledcheck(false);
    setClassadd("");
    setCorrect(null);    // Reset 'correct' state
    setCheck(false);     // Reset 'check' state
    setAns("");          // Reset selected answer
  };

  // Check if questiondata.data or questions is undefined or empty
  if (questiondata.isLoading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  if (questiondata.isError) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  if (
    !questiondata.data ||
    !questiondata.data.questions ||
    questiondata.data.questions.length === 0
  ) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  return (
    <>
      <section>
        {/* Header Section */}
        <div className="top-header-quiz">
          <Header />
          {/* Quiz Section */}
          <section className="ap">
            <Container>
              <img
                src={BGRight1}
                alt="Splatter"
                className="splatter-image-right-1"
              />
              <img
                src={BGRight2}
                alt="Splatter"
                className="splatter-image-right-2"
              />

              <Row className="pb-0">
                <Col sm={12}>
                  <div className="ap-heading mb-5 pt-5">
                    <Row className="quiz_row_page">
                      <Col sm={12} className="quiz_top_header_button">
                        {/* Display question numbers */}
                        {questiondata.data.questions.map((unit, index) => (
                          <p
                            className={`${
                              index === current ? "quizquctionnumber" : ""
                            }`}
                            key={index}
                          >
                            {index + 1}
                          </p>
                        ))}
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  {/* Display current question */}
                  <div className="quiz_question_top">
                    <p>
                      {HTMLReactParser(
                        questiondata.data.questions[current].question
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
        {/* Main Quiz Section */}
        <section className="unit-page pt-0 pb-120">
          <Container>
            <Row>
              <Col sm={12}>
                <Row>
                  <Col sm={6}>
                    {/* Display answer options */}
                    <div className="quiz_main_content pt-30 pb-60">
                      <Form>
                        {["radio"].map((type) => (
                          <div key={`inline-${type}`} className="mb-3">
                            {/* Display radio buttons for answer options */}
                            <Form.Check
                              onClick={() => {
                                setAns("A");
                                setCheck(true);
                              }}
                              className={`${
                                classadd === "highlight-correct-A"
                                  ? "highlight-correct"
                                  : ""
                              } chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].A
                              )}
                              name="group1"
                              type="radio"
                              id="inline-radio-1"
                              disabled={disabledcheck}
                              checked={ans === "A"}
                            />
                            <Form.Check
                              onClick={() => {
                                setAns("B");
                                setCheck(true);
                              }}
                              className={`${
                                classadd === "highlight-correct-B"
                                  ? "highlight-correct"
                                  : ""
                              } chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].B
                              )}
                              name="group1"
                              type="radio"
                              id="inline-radio-2"
                              disabled={disabledcheck}
                              checked={ans === "B"}
                            />
                            <Form.Check
                              onClick={() => {
                                setAns("C");
                                setCheck(true);
                              }}
                              className={`${
                                classadd === "highlight-correct-C"
                                  ? "highlight-correct"
                                  : ""
                              } chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].C
                              )}
                              name="group1"
                              type="radio"
                              id="inline-radio-3"
                              disabled={disabledcheck}
                              checked={ans === "C"}
                            />
                            <Form.Check
                              onClick={() => {
                                setAns("D");
                                setCheck(true);
                              }}
                              className={`${
                                classadd === "highlight-correct-D"
                                  ? "highlight-correct"
                                  : ""
                              } chack_button_style_quiz`}
                              label={HTMLReactParser(
                                questiondata.data.questions[current].D
                              )}
                              name="group1"
                              type="radio"
                              id="inline-radio-4"
                              disabled={disabledcheck}
                              checked={ans === "D"}
                            />
                          </div>
                        ))}
                      </Form>
                    </div>
                  </Col>
                </Row>
                <div className="quiz_main_bottom_content">
                  <p>
                    Note: **Get your result by clicking <span>Check</span> button
                    and then click continue for the next question.
                  </p>
                </div>
                <div className="display-flex quiz-page-style ">
                  <div className="quiz_bottom_button">
                    <>
                      {disabledcheck === false && (
                        <>
                          {check === true ? (
                            <Button
                              onClick={saveHandler}
                              className="quiz-box green-button"
                              disabled={!ans}
                            >
                              Check
                            </Button>
                          ) : (
                            <>
                              {current + 1 ===
                              questiondata.data.questions.length ? (
                                exit
                              ) : (
                                <Button
                                  className="quiz-box green-button"
                                  onClick={saveHandler}
                                  disabled={!ans}
                                >
                                  Check
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  </div>

                  {/* Display correct/wrong feedback */}
                  {correct}
                  <div className="quiz_bottom_right">
                    <p>Save time with our</p>
                    <Button variant="popup-button-1" onClick={handleShow}>
                      Keyboard Shortcuts
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* Keyboard Shortcuts Modal */}
        <Modal
          className="Keyboard-Shortcuts"
          size="lg"
          centered
          show={showh}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body className="pb-120">
            <h4 className="mb-30">Keyboard Shortcuts</h4>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Submit Answer or Continue</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>ENTER</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Select Multiple Choice Answer</p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>a</p>
                <p>b</p>
                <p>c</p>
                <p>d</p>
              </div>
            </div>
            <div className="Keyboard-Shortcuts-box-content">
              <p>Reset Blanks Exercise </p>
              <div className="Keyboard-Shortcuts-box-content-button">
                <p>R</p>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Footer />
      </section>

      {/* Display the Quizpopup component when model is true */}
      {model === true ? (
        <Quizpopup photo={tempdata[1]} hide={() => setModel(false)} />
      ) : null}
    </>
  );
};

export default Box;
