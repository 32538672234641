import React, { useState } from 'react';
import './popupvideo.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
const Popupvideo = (props) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>


      <div className="photo_popup_main modal Formula-popup">
        <div className="main-photo-popup modal-dialog modal-xl">

          <Modal.Header className="full-img-popup" closeButton onClick={props.hide}>
            <Modal.Title></Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <video
              id="VisaChipCardVideo"
              autoPlay
              controls
              controlsList="nodownload">
              <source
                src={props.video}
                type="video/mp4"
              />
            </video>
          </Modal.Body>
        </div>
      </div>
    </>
  );
};

export default Popupvideo;
