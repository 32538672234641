import React from 'react';
import './blog.css';
import Header from '../layout/header/Header';
import Footer from '../layout/footer/Footer';
import { Col, Row, Container } from 'react-bootstrap';
import img_1 from '../img/blog_1.png';
import { Link } from 'react-router-dom';
import { useGetAllfindblogQuery } from '../services/aplms';
import { useGetAllblogcategoryQuery } from "../services/aplms";
import Loader from '../loader/Loader';
import Error from '../errordata/Error';
import HTMLReactParser from 'html-react-parser';

const Blogsingle = (props) => {
  window.scrollTo(0, 0);
  const blogInfo = useGetAllfindblogQuery(props.match.params.id);
  // const blogcategory = useGetAllblogcategoryQuery();
  if (blogInfo.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (blogInfo.isError) return <Error />;

  console.log(blogInfo.data)




  return (

    <>

      {
        blogInfo.data ? (
          <section className="blog">
            <Header />
            <Container className="pb-120">
              <Row>
                <Col sm={12}>
                  <div className="blog-heading">
                    <Row className="flex-end">
                      <Col sm={12}>
                        <h2>Blog</h2>

                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>

                <Col sm={8}>

                  <div className="blog-main-box mb-120">
                    <div className="blog-box-left pb-60 mt-60">
                      <img src={blogInfo.data.photo} />
                      <div className="blog-post-time mb-30 mt-30">
                        {/* <p>POSTED ON {blog.updated_at}</p> */}
                        <p>POSTED ON {new Date(blogInfo.data.updated_at).toLocaleDateString()}</p>
                        <p>
                          TAGGED WITH: <span>Num8ers . New Features</span>
                        </p>
                      </div>
                      <h2 className="mb-60">{HTMLReactParser(blogInfo.data.description)}</h2>

                    </div>
                  </div>

                </Col>

                <Col sm={4}>
                  <div className="blog-box-right mt-60">
                    <h4>Browse all categories</h4>
                    <ul>
                      <li>
                        <Link
                          to="/Blog-single"
                          className="blog-right-button">
                          AP
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/Blog-single"
                          className="blog-right-button">
                          IB
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
            <Footer />
          </section>
        ) : (
          <Error />
        )

      }
    </>
  );
};

export default Blogsingle;
