import React from 'react';
import './charting.css';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import img_1 from '../img/about.png';
import ChartingBg from "../img/Charting Bg.png"; // Import the background image
import BGMiddle from "../img/BG Middle.png"; // Import the background image
const Charting = () => {
  return (
    <>
      <section className="charting-top-box pt-120 pb-120 desktop-view charting-container">
   
        <Container>
          <Row>
            <Col sm={12}>
              <h2 className="title">Charting Paths, Igniting Passions.</h2>
            </Col>
          </Row>
          <Row className="align-items pt-60">
            <Col sm={6}>
              <div className="charting-left-img">
                <img src={img_1} />
              </div>

              <img
            src={BGMiddle}
            alt="Splatter"
            className="splatter-image-middle-2"
          />
            </Col>
            <Col sm={6}>
              <div className="charting-right-content">
                <h4>Challenge Yourself</h4>
                <p>
                  Unleash your potential. Pit yourself against our vast bank of
                  questions, and gear up for the grand showdown — your exam day.
                  <br></br>
                  <br></br>
                  Precision-crafted questions — tailored for the AP, IB, GCSE
                  and IGCSE battlegrounds. A treasure trove of 20,000+ questions
                  — meticulously classified by topic and nuance.<br></br>
                  <br></br>
                  Go old school — print, pen down, and conquer your tests
                  offline. Level up — from rookie challenges to master quests as
                  you sharpen your acumen.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      
      <section className="charting-top-box pt-60 pb-60 mobile-view charting-container">
        <div className="charting-background"></div> {/* Background splatter */}
        <Container>
          <Row className="align-items">
            <Col sm={6}>
              <div className="charting-left-img">
                <img src={img_1} />
              </div>
            </Col>
            <Col sm={6}>
              <div className="charting-right-content">
                <h4>Challenge Yourself</h4>
                <p>
                  Unleash your potential. Pit yourself against our vast bank of
                  questions, and gear up for the grand showdown — your exam day.
                  <br></br>
                  <br></br>
                  Precision-crafted questions — tailored for the AP, IB, GCSE
                  and IGCSE battlegrounds. A treasure trove of 20,000+ questions
                  — meticulously classified by topic and nuance.<br></br>
                  <br></br>
                  Go old school — print, pen down, and conquer your tests
                  offline. Level up — from rookie challenges to master quests as
                  you sharpen your acumen.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Charting;
