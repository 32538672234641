import React from 'react';
import './SquaresArt.css';

const SquaresArt = () => {
  const squareCount = 8;     // Number of squares
  const size = 600;          // Size (width/height) for each square
  const centerX = 350;       // Base center X for the first square
  const centerY = 350;       // Base center Y for the first square
  const offset = 8;          // 2px offset in x & y per subsequent square
  const borderRadius = 55;   // Rounded corners

  return (
    <svg className="squares-art" width="900" height="900">
      {Array.from({ length: squareCount }).map((_, index) => {
        // Offset each square slightly further down/right than the previous
        const xPosition = centerX - size / 2 + index * offset;
        const yPosition = centerY - size / 2 + index * offset;

        return (
          <rect
            key={index}
            className="square"
            x={xPosition}
            y={yPosition}
            width={size}
            height={size}
            rx={borderRadius}
            ry={borderRadius}
            fill="none"
            stroke="#B660DE"
            strokeWidth={0.01 + index * 0.1} // Increases stroke width by 0.5 for each square
            style={{
              transformOrigin: '350px 350px',
              '--index': index,
            }}
          />
        );
      })}
    </svg>
  );
};

export default SquaresArt;
