import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const aplmsApi = createApi({
  // The unique key that defines where the Redux store will store our cache.
  reducerPath: "aplmsApi",

  // The base query to request data.
  // RTK Query ships with fetchBaseQuery, which is a lightweight fetch wrapper that automatically handles request headers and response parsing in a manner similar to common libraries like axios.
  baseQuery: fetchBaseQuery({
    baseUrl: "https://num8ers.cloud/api",
  }),

  endpoints: (builder) => ({
    getAllcurriculum: builder.query({
      query: () => ({
        url: "curriculum",
        method: "GET",
      }),
    }),

    getFindcurriculum: builder.query({
      query: (slug) => ({
        url: `findcurriculum/${slug}`,
        method: "GET",
      }),
    }),

    getAllcoursehome: builder.query({
      query: () => ({
        url: "apsubject1",
        method: "GET",
      }),
    }),

    getAllsubject: builder.query({
      query: (slug) => ({
        url: `apsubject/${slug}`,
        method: "GET",
      }),
    }),
    getAllcourses: builder.query({
      query: (slug) => ({
        url: `apcouresesdata/${slug}`,
        method: "GET",
      }),
    }),

    getAllletestcourses: builder.query({
      query: () => ({
        url: `latestcourses`,
        method: "GET",
      }),
    }),

    getAllunit: builder.query({
      query: (slug) => ({
        url: `apunit/${slug}`,
        method: "GET",
      }),
    }),
    getAlltopic: builder.query({
      query: (slug) => ({
        url: `aptopicdata/${slug}`,
        method: "GET",
      }),
    }),
    getAllquestionbankpaper: builder.query({
      query: (slug) => ({
        url: `questionbankpaper/${slug}`,
        method: "GET",
      }),
    }),
    getAllquestionbankquestion: builder.query({
      query: (slug) => ({
        url: `questionbankquestion/${slug}`,
        method: "GET",
      }),
    }),
    getAllquestionbankpaperpdf: builder.query({
      query: (slug) => ({
        url: `questionbankpaperpdf/${slug}`,
        method: "GET",
      }),
    }),
    getAllkeyconceptintro: builder.query({
      query: (slug) => ({
        url: `apkeyconceptintrovideo/${slug}`,
        method: "GET",
      }),
    }),
    getAllkeyconceptpaper: builder.query({
      query: (slug) => ({
        url: `keyconceptpaper/${slug}`,
        method: "GET",
      }),
    }),
    getAllkeyconceptquestion: builder.query({
      query: (slug) => ({
        url: `keyconceptquestion/${slug}`,
        method: "GET",
      }),
    }),

    getAllkeyconceptpaperpdf: builder.query({
      query: (slug) => ({
        url: `keyconceptpaperpdf/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpaperdate: builder.query({
      query: (slug) => ({
        url: `appastpaperdate/${slug}`,
        method: "GET",
      }),
    }),
    getAllpastpaper: builder.query({
      query: (slug) => ({
        url: `appastpaper/${slug}`,
        method: "GET",
      }),
    }),
    getAllpastpaperquestion: builder.query({
      query: (slug) => ({
        url: `appastpaperquestion/${slug}`,
        method: "GET",
      }),
    }),
    getAllfaqs: builder.query({
      query: () => ({
        url: "apfaqs",
        method: "GET",
      }),
    }),
    getAllwebsiteintro: builder.query({
      query: () => ({
        url: "apwebsiteintro",
        method: "GET",
      }),
    }),
    getAllterms: builder.query({
      query: () => ({
        url: "apterms",
        method: "GET",
      }),
    }),
    getAllpolicy: builder.query({
      query: () => ({
        url: "appolicy",
        method: "GET",
      }),
    }),
    getAllrefundpolice: builder.query({
      query: () => ({
        url: "aprefundpolicy",
        method: "GET",
      }),
    }),
    getAllblog: builder.query({
      query: () => ({
        url: "apblogs",
        method: "GET",
      }),
    }),
    getAllfindblog: builder.query({
      query: (id) => ({
        url: `apblogdatafind/${id}`,
        method: "GET",
      }),
    }),
    getAllblogcategory: builder.query({
      query: () => ({
        url: `apcategory`,
        method: "GET",
      }),
    }),
    getAllblogbycategory: builder.query({
      query: (slug) => ({
        url: `apblogcategory/${slug}`,
        method: "GET",
      }),
    }),

    getAllapteam: builder.query({
      query: () => ({
        url: "apteam",
        method: "GET",
      }),
    }),

    getSearchquestion: builder.query({
      query: (slug) => ({
        url: `apquestionfind/${slug}`,
        method: "GET",
      }),
    }),

    registerUser: builder.mutation({
      query: (user) => {
        return {
          url: "student/apregister",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    loginUser: builder.mutation({
      query: (user) => {
        return {
          url: "student/aplogin",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    logoutUser: builder.mutation({
      query: ({ token }) => {
        return {
          url: "student/aplogout",
          method: "POST",
          body: {},
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getLoggedUser: builder.query({
      query: (token) => {
        return {
          url: "student/aploggeduser",
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    changeUserPassword: builder.mutation({
      query: ({ actualData, token }) => {
        return {
          url: "student/apchangepassword",
          method: "POST",
          body: actualData,
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),

    updateProfile: builder.mutation({
      query: ({ actualData, token }) => {
        return {
          url: "student/updateprofile",
          method: "POST",
          body: actualData,
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),

    sendPasswordResetEmail: builder.mutation({
      query: (user) => {
        return {
          url: "student/apsend-reset-password-email",
          method: "POST",
          body: user,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    resetPassword: builder.mutation({
      query: ({ actualData, token }) => {
        return {
          url: `student/apreset-password/${token}`,
          method: "POST",
          body: actualData,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),

    getMonthplans: builder.query({
      query: (id) => ({
        url: `student/apmonthplans/${id}`,
        method: "GET",
      }),
    }),

    getYearlyplans: builder.query({
      query: () => ({
        url: `student/apyearplans`,
        method: "GET",
      }),
    }),

    getCheckout: builder.query({
      query: (planId, token) => ({
        url: `student/checkout/${planId}`,
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
        },
      }),
    }),

    getPaymentstatus: builder.query({
      query: (token) => {
        return {
          url: `student/showappaymentstatus`,
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
        };
      },
    }),
    getQuizunitefind: builder.query({
      query: (slug) => ({
        url: `findquizunit/${slug}`,
        method: "GET",
      }),
    }),
    getQuizquction: builder.query({
      query: (slug) => ({
        url: `/random-quiz-questions/${slug}`,
        method: "GET",
      }),
    }),

    getAllquizquction: builder.query({
      query: (slug) => ({
        url: `randomquizquestions/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpapercurriculum: builder.query({
      query: () => ({
        url: "pastpapercurriculums",
        method: "GET",
      }),
    }),

    getAllpastpapercourses: builder.query({
      query: (curriculumId) => ({
        url: `pastpapercourses/${curriculumId}`,
        method: "GET",
      }),
    }),

    getAllpastpapersubject: builder.query({
      query: (slug) => ({
        url: `pastpapersubjects/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpaperselectpaper: builder.query({
      query: (slug) => ({
        url: `pastpaperselectpaper/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpaperyear: builder.query({
      query: (slug) => ({
        url: `pastpaperselectyear/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpaperseason: builder.query({
      query: (slug) => ({
        url: `pastpaperselectseason/${slug}`,
        method: "GET",
      }),
    }),

    getAllpastpaperzone: builder.query({
      query: (slug) => ({
        url: `pastpaperselectzone/${slug}`,
        method: "GET",
      }),
    }),
    getAllpastpaperselectquction: builder.query({
      query: (slug) => ({
        url: `pastpaperselectquestions/${slug}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetAllcurriculumQuery,
  useGetFindcurriculumQuery,
  useGetAllcoursehomeQuery,
  useGetAllsubjectQuery,
  useGetAllcoursesQuery,
  useGetAllletestcoursesQuery,
  useGetAllunitQuery,
  useGetAlltopicQuery,
  useGetAllquestionbankpaperQuery,
  useGetAllquestionbankpaperpdfQuery,
  useGetAllquestionbankquestionQuery,
  useGetAllkeyconceptintroQuery,
  useGetAllkeyconceptpaperQuery,
  useGetAllkeyconceptpaperpdfQuery,
  useGetAllkeyconceptquestionQuery,
  useGetAllpastpaperdateQuery,
  useGetAllpastpaperQuery,
  useGetAllpastpaperquestionQuery,
  useGetAllfaqsQuery,
  useGetAllwebsiteintroQuery,
  useGetAlltermsQuery,
  useGetAllpolicyQuery,
  useGetAllrefundpoliceQuery,
  useGetAllblogQuery,
  useGetAllfindblogQuery,
  useGetAllblogcategoryQuery,
  useGetAllblogbycategoryQuery,
  useGetAllapteamQuery,
  useGetSearchquestionQuery,
  useRegisterUserMutation,
  useLoginUserMutation,
  useLogoutUserMutation,
  useGetLoggedUserQuery,
  useChangeUserPasswordMutation,
  useSendPasswordResetEmailMutation,
  useResetPasswordMutation,
  useGetMonthplansQuery,
  useGetYearlyplansQuery,
  useGetCheckoutQuery,
  useGetPaymentstatusQuery,
  useGetQuizunitefindQuery,
  useGetQuizquctionQuery,
  useGetAllquizquctionQuery,
  useUpdateProfileMutation,
  useGetAllpastpapercurriculumQuery,
  useGetAllpastpapercoursesQuery,
  useGetAllpastpapersubjectQuery,
  useGetAllpastpaperselectpaperQuery,
  useGetAllpastpaperyearQuery,
  useGetAllpastpaperseasonQuery,
  useGetAllpastpaperzoneQuery,
  useGetAllpastpaperselectquctionQuery,
} = aplmsApi;
