import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useGetMonthplansQuery } from "../../services/aplms";
import Error from "../../errordata/Error";
import Loader from "../../loader/Loader";
import { post } from "../../services/CallAPi";
import Header from "../../layout/header/Header";
import Footer from "../../layout/footer/Footer";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import "./Payment.css";
import { Button } from "react-bootstrap";
import axios from "axios";
import { FaCheck } from "react-icons/fa";
import BGRight1 from "../../img/Bg Right 1.png"; // Import the background image
import BGRight2 from "../../img/Bg Right 2.png"; // Import the background image
import BGMiddle from "../../img/BG Middle.png"; // Import the background image

const Payment = () => {
  const [setCheckedGroup, setsetCheckedGroup] = useState("group1");
  const [intervalCount, setIntervalCount] = useState(1);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedPlanids, setSelectedPlanids] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const plan = useGetMonthplansQuery(intervalCount);
  const history = useHistory();

  const fixedPrices = {
    1: 29.0, // 1 Month
    3: 49.0, // 3 Months
    6: 79.0, // 6 Months
    12: 135.0, // 12 Months
  };

  useEffect(() => {
    if (plan.data && plan.data.data.length > 0) {
      const firstCourse = plan.data.data[0].couresname;
      setSelectedCourses([firstCourse]);
    }
  }, [plan.data]);

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    selectedCourses.forEach((course) => {
      const selectedCourse = plan.data.data.find(
        (item) => item.couresname === course
      );
      if (selectedCourse) {
        totalPrice += parseFloat(selectedCourse.price);
      }
    });
    return totalPrice.toFixed(2); // Fix to two decimal places
  };

  useEffect(() => {
    setTotalPrice(calculateTotalPrice());
  }, [selectedCourses, plan.data, intervalCount]);

  if (plan.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (plan.isError) return <Error />;

  const handleClick = async () => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    } else {
      const token = localStorage.getItem("token");
      console.log(token);
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        // Create an array to hold all selected plan IDs
        const allSelectedPlanIds = [];

        // Iterate over selectedPlanids to push all selected plan IDs into allSelectedPlanIds
        selectedPlanids.forEach((planId) => {
          allSelectedPlanIds.push({ plan_id: planId });
        });

        // Send all selected plan IDs in a single request
        const { data } = await axios.post(
          `student/checkout`,
          { plans: allSelectedPlanIds },
          config
        );
        // console.log(data.url);
        window.location.replace(data.url);
      } catch (error) {
        console.error("Error during checkout:", error);
        // Handle checkout errors appropriately (e.g., display an error message)
      }
    }
  };

  const handleCheck = (groupName, count) => {
    setsetCheckedGroup(groupName);
    setIntervalCount(count);
  
    // If there's already a course selected, retain it
    if (selectedCourses.length === 0 && plan.data && plan.data.data.length > 0) {
      const firstCourse = plan.data.data[0].couresname;
      setSelectedCourses([firstCourse]);
      setSelectedPlanids([plan.data.data[0].id]);
    }
  };
  
  const handleCourseSelection = (course, planId) => {
    const index = selectedCourses.indexOf(course);
    if (index === -1) {
      setSelectedCourses([...selectedCourses, course]);
      setSelectedPlanids([...selectedPlanids, planId]); // Add plan ID
    } else {
      const updatedCourses = [...selectedCourses];
      const updatedPlanIds = [...selectedPlanids];
      updatedCourses.splice(index, 1);
      const planIdIndex = updatedPlanIds.findIndex((id) => id === planId);
      updatedPlanIds.splice(planIdIndex, 1);
      setSelectedCourses(updatedCourses);
      setSelectedPlanids(updatedPlanIds);
    }
  };

  const plansBySubject = {};
  plan.data.data.forEach((plan) => {
    const subjname = plan.subjname;
    if (!plansBySubject[subjname]) {
      plansBySubject[subjname] = [];
    }
    plansBySubject[subjname].push(plan);
  });

  return (
    <section className="payment-page">
      <Header />
      <img src={BGRight1} alt="Splatter" className="splatter-image-right-1" />
      <img src={BGRight2} alt="Splatter" className="splatter-image-right-2" />
      <img src={BGMiddle} alt="Splatter" className="splatter-image-middle" />
      <Container className="pb-120">
        <Row>
          <Col sm={12}>
            <div className="payment-page-heading text-center">
              <h2>Pricing</h2>
              <p>
                Thank you for supporting. <br />
                We are working hard to build amazing resources for students
                world wide.
              </p>
            </div>
          </Col>
        </Row>
        <div className="my-green-form-container">
          <ul className="my-green-form-list">
            {Object.keys(fixedPrices).map((interval) => (
              <li key={`radio-${interval}`}>
                <input
                  type="radio"
                  name="myGreenRadioGroup"
                  id={`radio-${interval}`}
                  checked={setCheckedGroup === `group${interval}`}
                  onChange={() =>
                    handleCheck(`group${interval}`, parseInt(interval))
                  }
                />
                <label htmlFor={`radio-${interval}`}>
                  {interval} Month{interval > 1 ? "s" : ""}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <Row className="pb-30 justify-content-center">
          {Object.keys(fixedPrices).map((interval) => (
            <Col sm={3} key={interval} className="mx-auto">
              <div className="payment_card_box">
                <div className="payment_card_price">
                  <p>${fixedPrices[interval].toFixed(2)}</p>
                </div>
                <h4 className="payment_card_title">{interval} Months</h4>

                <div className="payment_card_box_body">
                  <h4>Full Curriculum</h4>
                  <ul>
                    <li>
                      <FaCheck />
                      <span>Key Concepts</span>
                    </li>
                    <li>
                      <FaCheck />
                      <span>Question Bank</span>
                    </li>
                    <li>
                      <FaCheck />
                      <span>Past Papers</span>
                    </li>
                    <li>
                      <FaCheck />
                      <span>Quizzes</span>
                    </li>
                  </ul>
                </div>

                <button
  className={`checkbox_button ${
    setCheckedGroup === `group${interval}` ? "selected" : ""
  }`}
  onClick={() => handleCheck(`group${interval}`, parseInt(interval))}
>
  {setCheckedGroup === `group${interval}` && (
    <FaCheck style={{ marginRight: "8px", color: "#000" }} />
  )}
  <span>
    {setCheckedGroup === `group${interval}` ? "Selected" : "Subscribe"}
  </span>
</button>

              </div>
            </Col>
          ))}
        </Row>
        ;
        <Button onClick={handleClick} className="bottom-button-more">
          Get started - ${totalPrice}
        </Button>
        {/* <div>
          <h2>Selected Courses:</h2>
          <ul>
            {selectedCourses.map((course, index) => (
              <li key={index}>{course}</li>
            ))}
          </ul>
        </div> */}
      </Container>
      <Footer />
    </section>
  );
};

export default Payment;
