import { React, useState } from 'react';
import img_2 from '../img/question-img-1.png';
import img_3 from '../img/icon/Video_light.png';
import img_4 from '../img/book-img.png';
import Collapse from 'react-bootstrap/Collapse';
import Col from 'react-bootstrap/Col';
import { useGetAllkeyconceptintroQuery } from '../services/aplms';
import Loader from '../loader/Loader';
import HTMLReactParser from 'html-react-parser';
import Error from '../errordata/Error';

const Intro = (props) => {
  const [open, setOpen] = useState(true);
  const [open1, setOpen1] = useState(false);

  const slug = props.propsintroData;
  const intro = useGetAllkeyconceptintroQuery(slug);
  if (intro.isLoading)
    return (
      <div>
        <Loader />
      </div>
    );

  if (intro.data[0] == null)
    return (
      <div style={{ 
        textAlign: 'center', 
        padding: '40px',
        width: '100%',
      }}>
        <h2 style={{
          fontSize: '32px',
          color: '#666',
          marginBottom: '20px',
          marginTop: '40px',
        }}>
          Coming Soon...
        </h2>
        <p style={{
          fontSize: '18px',
          color: '#888',
          maxWidth: '600px',
          margin: '0 auto'
        }}>
          We're working hard to bring you this content. Please check back later!
        </p>
      </div>
    );
  if (intro.isError) return <h1>An error occured {intro.error.error}</h1>;

  console.log(intro);
  return (
    <>
      <Collapse in={slug}>
        <div id="example-collapse-text">
          <h4>{intro.data[0].aptopicname}</h4>
          <div className="question-video-img">
            <div className="unit-page-box-box-1 video_full">
              <video
                id="VisaChipCardVideo"
                controls
                controlsList="nodownload">
                <source
                  className="video-img"
                  src={intro.data[0].video}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <p>{HTMLReactParser(intro.data[0].description)}</p>
        </div>
      </Collapse>
    </>
  );
};

export default Intro;
